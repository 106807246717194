import React from "react";
import Styles from "./executionCard.module.scss";
import {
  BiMessage,
  BiPencil,
  BiPhone,
  BiPhoneCall,
  BiPhoneOutgoing,
  BiTrash,
} from "react-icons/bi";
import Phone from "@assets/img/phone.svg";
import SMS from "@assets/img/sms.svg";
import moment from "moment/moment";
import { Button, Modal, ModalHeader } from "@components";
import ExecutionAction from "./executionAction";
import { useMutation } from "@tanstack/react-query";
import { publishExecutionPlan, unPublishExecutionPlan } from "services";
import toast from "react-hot-toast";
import TopLoader from "components/AppLoader";
import { TOAST_MESSAGES } from "@utils";
import ExpiredIcon from "@assets/img/expired.png";
import WarningIcon from "@assets/img/warning.svg";
import { Tooltip } from "@mui/material";
import { MdCallMissedOutgoing } from "react-icons/md";

const ExecutionCard = ({
  id,
  title,
  is_published,
  isRestartRequired,
  executionPlanTrafficCycle,
  asr,
  acd,
  active_calls,
  success_percentage,
  billed_duration,
  refetch,
  channel_stats,
  db_stats,
  db_stats_day,
}) => {
  const { mutate, isLoading, error } = useMutation(publishExecutionPlan, {
    onSuccess: (res) => {
      if (res) {
        toast.success(TOAST_MESSAGES.RUN_TEST_CASE);
        refetch();
      }
    },
    onError: ({ response }) => {
      const { data } = response;
    },
  });

  const { mutate: UnPublishMutate, isLoading: unPublishLoading } = useMutation(
    unPublishExecutionPlan,
    {
      onSuccess: (res) => {
        if (res) {
          toast.success(TOAST_MESSAGES.STOP_TEST_CASE);
          refetch();
        }
      },
      onError: ({ response }) => {
        const { data } = response;
      },
    }
  );

  const publish = () => {
    mutate(executionPlanTrafficCycle?.execution_plan_id);
  };

  const unPublish = () => {
    UnPublishMutate(executionPlanTrafficCycle?.execution_plan_id);
  };

  const calculate = (duration, totalMinutes) => {
    if (totalMinutes && duration) {
      return ((duration / totalMinutes) * 100).toFixed(2);
    }
    return 0;
  };

  return (
    <>
      <TopLoader showLoading={isLoading || unPublishLoading}>
        <div
          className={`${Styles.executionCardContainer} ${
            is_published && Styles.ShadowSuccess
          }
          ${
            is_published &&
            moment().diff(
              moment(
                executionPlanTrafficCycle?.traficCycle?.trafficProperty
                  ?.validity_end_date
              )
            ) > 0 &&
            Styles.ShadowDanger
          } 
               ${
                 is_published &&
                 isRestartRequired &&
                 moment().diff(
                   moment(
                     executionPlanTrafficCycle?.traficCycle?.trafficProperty
                       ?.validity_end_date
                   )
                 ) < 0 &&
                 Styles.ShadowDanger
               }  
          }`}
        >
          <div className="absolute right-5 flex">
            {is_published &&
              isRestartRequired &&
              moment().diff(
                moment(
                  executionPlanTrafficCycle?.traficCycle?.trafficProperty
                    ?.validity_end_date
                )
              ) < 0 && (
                <Tooltip
                  title="You need to restart the Test Case"
                  placement="top"
                >
                  <img src={WarningIcon} width="28" className="mr-3" />
                </Tooltip>
              )}
            <ExecutionAction
              id={id}
              isRunning={is_published}
              onClick={() => {
                is_published ? unPublish() : publish();
              }}
              error={error?.response?.data}
            />
          </div>
          <div className={`${Styles.title} relative`}>
            <img
              src={
                executionPlanTrafficCycle?.traficCycle?.traffic_cycle_type == 2
                  ? Phone
                  : SMS
              }
              width={
                executionPlanTrafficCycle?.traficCycle?.traffic_cycle_type == 2
                  ? "15"
                  : "21"
              }
              className="absolute"
              style={{ left: "-18px" }}
            />
            <h4>{title}</h4>
            <div className="flex justify-between">
              <p>
                {
                  executionPlanTrafficCycle?.traficCycle?.supplier_trunk
                    ?.trunk_name
                }
              </p>
              <p
                className={`${Styles.status} ml-5 ${
                  moment().diff(
                    moment(
                      executionPlanTrafficCycle?.traficCycle?.trafficProperty
                        ?.validity_end_date
                    )
                  ) > 0
                    ? "border-danger"
                    : is_published
                    ? "border-success"
                    : "border-danger"
                }`}
              >
                {moment().diff(
                  moment(
                    executionPlanTrafficCycle?.traficCycle?.trafficProperty
                      ?.validity_end_date
                  )
                ) > 0
                  ? "Expired"
                  : is_published
                  ? "Running"
                  : "Stopped"}
              </p>
            </div>
          </div>

          <div className={`${Styles.body}`}>
            <div>
              <div className="flex justify-between" style={{ width: "100%" }}>
                {executionPlanTrafficCycle?.traficCycle?.traffic_cycle_type ==
                  2 && (
                  <div className={`${Styles.propertyCard} bg-green-500`}>
                    <BiPhoneCall
                      color="#fff"
                      className="absolute top-1 left-1"
                    />
                    <p>{channel_stats?.active_calls || 0}</p>
                    <p>Active Calls</p>
                  </div>
                )}
                {executionPlanTrafficCycle?.traficCycle?.traffic_cycle_type ==
                  3 && (
                  <div className={`${Styles.propertyCard} bg-green-500`}>
                    <BiMessage color="#fff" className="absolute top-1 left-1" />
                    <p>
                      {executionPlanTrafficCycle?.traficCycle?.trafficProperty
                        ?.is_target_per_day
                        ? db_stats_day?.sms_count
                        : db_stats?.sms_count || 0}
                    </p>
                    <p>Success</p>
                  </div>
                )}
                <div className={`${Styles.propertyCard} bg-green`}>
                  {executionPlanTrafficCycle?.traficCycle?.traffic_cycle_type ==
                  2 ? (
                    <p>
                      {executionPlanTrafficCycle?.traficCycle?.trafficProperty
                        ?.is_target_per_day
                        ? (db_stats_day?.success_percentage || 0).toFixed(2)
                        : (db_stats?.success_percentage || 0).toFixed(2) || 0}
                      %
                    </p>
                  ) : (
                    <p>
                      {executionPlanTrafficCycle?.traficCycle?.trafficProperty
                        ?.is_target_per_day
                        ? (db_stats_day?.success_ratio || 0).toFixed(2)
                        : (db_stats?.success_ratio || 0).toFixed(2) || 0}
                      %
                    </p>
                  )}
                  <p>Success Ratio</p>
                </div>
              </div>
              <div
                className="mt-2 flex justify-between"
                style={{ width: "100%" }}
              >
                <div className={`${Styles.propertyCard} bg-purple`}>
                  <p>
                    {executionPlanTrafficCycle?.traficCycle
                      ?.traffic_cycle_type == 2
                      ? executionPlanTrafficCycle?.traficCycle?.trafficProperty
                          ?.is_target_per_day
                        ? db_stats_day?.acd
                        : db_stats?.acd
                      : executionPlanTrafficCycle?.traficCycle?.trafficProperty
                          ?.is_target_per_day
                      ? db_stats_day?.received_dlr_count
                      : db_stats?.received_dlr_count}
                  </p>
                  {executionPlanTrafficCycle?.traficCycle?.traffic_cycle_type ==
                  2 ? (
                    <p>ACD</p>
                  ) : (
                    <p>Delivered</p>
                  )}
                </div>
                <div className={`${Styles.propertyCard} bg-orange`}>
                  <p>
                    {executionPlanTrafficCycle?.traficCycle
                      ?.traffic_cycle_type == 2
                      ? executionPlanTrafficCycle?.traficCycle?.trafficProperty
                          ?.is_target_per_day
                        ? db_stats_day?.asr
                        : db_stats?.asr
                      : executionPlanTrafficCycle?.traficCycle?.trafficProperty
                          ?.is_target_per_day
                      ? db_stats_day?.failed_sms_count
                      : db_stats?.failed_sms_count}
                  </p>
                  {executionPlanTrafficCycle?.traficCycle?.traffic_cycle_type ==
                  2 ? (
                    <p>ASR</p>
                  ) : (
                    <p>Failed </p>
                  )}
                </div>
              </div>
            </div>

            <div
              className={Styles.gauge}
              style={{ width: "175px", rotation: "120deg" }}
            >
              <div
                className={Styles.percentage}
                style={{
                  transform: `rotate(${
                    (calculate(
                      executionPlanTrafficCycle?.traficCycle
                        ?.traffic_cycle_type == 2
                        ? executionPlanTrafficCycle?.traficCycle
                            ?.trafficProperty?.is_target_per_day
                          ? db_stats_day?.total_billed_duration
                          : db_stats?.total_billed_duration
                        : executionPlanTrafficCycle?.traficCycle
                            ?.trafficProperty?.is_target_per_day
                        ? db_stats_day?.sms_count
                        : db_stats?.sms_count,
                      executionPlanTrafficCycle?.traficCycle
                        ?.traffic_cycle_type == 2
                        ? executionPlanTrafficCycle?.traficCycle
                            ?.trafficProperty?.total_minutes
                        : executionPlanTrafficCycle?.traficCycle
                            ?.trafficProperty?.total_sms
                    ) /
                      100) *
                    180
                  }deg)`,
                }}
              ></div>
              <div className={Styles.mask}></div>
              <span className={Styles.value}>
                {calculate(
                  executionPlanTrafficCycle?.traficCycle?.traffic_cycle_type ==
                    2
                    ? executionPlanTrafficCycle?.traficCycle?.trafficProperty
                        ?.is_target_per_day
                      ? db_stats_day?.total_billed_duration
                      : db_stats?.total_billed_duration
                    : executionPlanTrafficCycle?.traficCycle?.trafficProperty
                        ?.is_target_per_day
                    ? db_stats_day?.sms_count
                    : db_stats?.sms_count,
                  executionPlanTrafficCycle?.traficCycle?.traffic_cycle_type ==
                    2
                    ? executionPlanTrafficCycle?.traficCycle?.trafficProperty
                        ?.total_minutes
                    : executionPlanTrafficCycle?.traficCycle?.trafficProperty
                        ?.total_sms
                )}
                %
              </span>
              <span className={Styles.currentCalls}>
                {executionPlanTrafficCycle?.traficCycle?.traffic_cycle_type == 2
                  ? executionPlanTrafficCycle?.traficCycle?.trafficProperty
                      ?.is_target_per_day
                    ? db_stats_day?.total_billed_duration
                    : db_stats?.total_billed_duration
                  : executionPlanTrafficCycle?.traficCycle?.trafficProperty
                      ?.is_target_per_day
                  ? db_stats_day?.sms_count
                  : db_stats?.sms_count}
                /
                {executionPlanTrafficCycle?.traficCycle?.traffic_cycle_type == 2
                  ? executionPlanTrafficCycle?.traficCycle?.trafficProperty
                      ?.total_minutes
                  : executionPlanTrafficCycle?.traficCycle?.trafficProperty
                      ?.total_sms || 0}
              </span>
            </div>
          </div>

          <span
            className={Styles.callsCount}
            style={{
              visibility:
                executionPlanTrafficCycle?.traficCycle?.traffic_cycle_type ==
                  3 && "hidden",
            }}
          >
            <BiPhoneOutgoing /> Total Calls:{" "}
            {executionPlanTrafficCycle?.traficCycle?.trafficProperty
              ?.is_target_per_day
              ? db_stats_day?.call_count
              : db_stats?.call_count}
          </span>
          <div className={Styles.footer}>
            <p className={Styles.duration}>
              {moment().diff(
                moment(
                  executionPlanTrafficCycle?.traficCycle?.trafficProperty
                    ?.validity_end_date
                )
              ) > 0 && <img src={ExpiredIcon} width={18} />}
              {moment(
                executionPlanTrafficCycle?.traficCycle?.trafficProperty
                  ?.validity_start_date
              ).format("DD MMM, YYYY")}{" "}
              -{" "}
              {moment(
                executionPlanTrafficCycle?.traficCycle?.trafficProperty
                  ?.validity_end_date
              ).format("DD MMM, YYYY")}
              {executionPlanTrafficCycle?.traficCycle?.trafficProperty
                ?.is_target_per_day && (
                <small className="ml-2">(Daily Target)</small>
              )}
            </p>

            <div className="flex">
              {/* <Button className={'mr-2'} as="a" icon={<BiTrash />}>Delete</Button> */}
              <Button
                as="navLink"
                icon={<BiPencil />}
                to={`/test-scenarios/scenario/${id}`}
              >
                Edit
              </Button>
            </div>
          </div>
        </div>
      </TopLoader>
    </>
  );
};

export default ExecutionCard;
