const ADDRESS_TON = [
    {
        address_ton_value: 'INTERNATIONAL',
    },
    {
        address_ton_value: 'NATIONAL',
    },
    {
        address_ton_value: 'NETWORK-SPECIFIC',
    },
    {
        address_ton_value: 'SUBSCRIBER-NUMBER',
    },
    {
        address_ton_value: 'ALPHANUMERIC',
    },
    {
        address_ton_value: 'ABBREVIATED',
    },
    {
        address_ton_value: 'UNKNOWN',
    },
]

export default ADDRESS_TON