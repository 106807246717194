import { Popover } from "@mui/material";
import React from "react";
import Styles from "./profileDropdown.module.scss";
import ChevronBottom from "@assets/img/chevron-bottom.svg";
import { useQueryClient } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetToken } from "redux/slices/authenticationSlice";
import { BiUser } from "react-icons/bi";
import { MdLogout, MdSettings } from "react-icons/md";
import { GrSystem } from "react-icons/gr";

const ProfileDropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { socket } = useSelector((state) => state.socketConnection);
  const { token } = useSelector((state) => state.authentication);

  const { user } = queryClient.getQueryData(["user"]) || {};

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const logout = () => {
    if (socket) socket.close();
    queryClient.removeQueries();
    dispatch(resetToken());
    navigate("/login");
  };

  return (
    <>
      <div className={Styles.profileDropdownWrapper} onClick={handleClick}>
        <div className={`${Styles.avatar} flex justify-center items-center`}>
          {user?.first_name.substring(0, 2).toUpperCase()}
        </div>
        <div className="flex flex-col">
          <p>{user?.first_name}</p>
          <p className="flex">
            {token?.selected_partition?.company_name
              ? token?.selected_partition?.company_name
              : "Admin"}{" "}
            <img
              src={ChevronBottom}
              className={`${open && "rotate-180"}`}
              alt="avatar"
            />
          </p>
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={Styles.popOver}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div>
          <ul className={Styles.dropdown}>
            {user?.tenants && user?.tenants.length > 1 && (
              <li className={Styles.active}>
                <Link to="/change-partition">
                  <GrSystem /> Change Partition
                </Link>
              </li>
            )}
            <li>
              <a onClick={logout}>
                <MdLogout /> Log Out
              </a>
            </li>
          </ul>
        </div>
      </Popover>
    </>
  );
};

export default ProfileDropdown;
