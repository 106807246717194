import React, { useState, useEffect } from 'react'
import { BiPlay, BiStop } from 'react-icons/bi';

import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from '@components';
import Styles from './executionCard.module.scss';
import { FaArrowRight, FaShare } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const ExecutionAction = ({ id, isRunning, onClick, error }) => {
    const [modal, setModal] = useState(false);

    const navigate = useNavigate()

    useEffect(() => {
        return () => {
            setModal(false)
        }
    }, [])

    useEffect(() => {
        if (error) {
            setModal(true)
        }

    }, [error])

    const dismissModal = () => {
        setModal(false);
    }

    const navigateToTestCase = () => {
        navigate(`/test-scenarios/scenario/${id}`)
    }

    return (
        <>
            <div className={`${Styles.executionAction} ${isRunning ? 'bg-red' : 'bg-purple'}`} onClick={onClick}>
                <span className={Styles.label}>{isRunning ? 'Stop' : 'Start'}</span>
                <span className={Styles.icon}>{isRunning ? <BiStop /> : <BiPlay />}</span>
            </div>

            {(modal && error) && <Modal id="testCaseValidationErrorModal" className={'modal-wrapper'} size="sm">
                <ModalHeader onClose={dismissModal}>Validation Error{error?.errors && error?.errors.length > 1 ? 's' : ''}</ModalHeader>
                <ModalBody>
                    <div>
                        <h4>Test Case unable to start due to Following
                            <strong> Error{error?.errors && error?.errors.length > 1 ? 's' : ''}</strong>:
                        </h4>
                        <br />
                        {error?.errors && error?.errors.length > 0 ? error?.errors.map((err) => (
                            <p className="m-0">
                                <FaArrowRight />&nbsp;&nbsp; {err}
                            </p>
                        )) : <p>{error?.statusPhrase}</p>}

                        <div className="mt-3">
                            <Button as='a' onClick={navigateToTestCase}>
                                <FaShare />&nbsp;Navigate to Traffic Cycle
                            </Button>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className='DangerOutline' type={'DangerOutline'} onClick={dismissModal}>Close</Button>
                </ModalFooter>
            </Modal >}
        </>
    )
}

export default ExecutionAction