const ADDRESS_NPI = [
    {
        address_npi_value: 'ISDN-E163-E164',
    },
    {
        address_npi_value: 'DATA-X.121',
    },
    {
        address_npi_value: 'TELEX-F.69',
    },
    {
        address_npi_value: 'LAND-MOBILE-E.212',
    },
    {
        address_npi_value: 'NATIONAL',
    },
    {
        address_npi_value: 'PRIVATE',
    },
    {
        address_npi_value: 'ERMES',
    },
    {
        address_npi_value: 'WAP-CLIENT-ID ',
    },
    {
        address_npi_value: 'ERMES',
    },
    {
        address_npi_value: 'UNKNOWN',
    },
]

export default ADDRESS_NPI