import React from "react";
import PropTypes from "prop-types";
import Styles from "./input.module.scss";
import { BiInfoCircle } from "react-icons/bi";

const Input = ({
  name,
  validationSchema,
  label,
  placeHolder,
  type = "text",
  error,
  errorMessage = null,
  register,
  flex = "flex-col",
  bottomElement,
  bottomElementClass = null,
  bottomElementCss,
  innerElement,
  innerElementCss,
  autoComplete = "off",
  ...props
}) => {
  return (
    <div className={`${Styles.inputWrapper} flex ${flex} w-full`}>
      {label && <label className={`mb-2 ${Styles.label}`}>{label}</label>}
      <div className={Styles.inputFieldContainer}>
        <input
          id={name}
          name={name}
          type={type}
          autoComplete={autoComplete}
          placeholder={placeHolder}
          className={`${error && "validation-error"} ${Styles.inputField}`}
          {...register(name, validationSchema)}
          {...props}
        />
        {error && errorMessage && (
          <small className={`text-red ${Styles.error}`}>
            <BiInfoCircle /> {errorMessage}
          </small>
        )}
        <div
          className={`${Styles.bottomElement} ${
            bottomElementClass && bottomElementClass
          }`}
          style={bottomElementCss}
        >
          {bottomElement}
        </div>
        {innerElement && (
          <div className={Styles.innerElement} style={innerElementCss}>
            {innerElement}
          </div>
        )}
      </div>
    </div>
  );
};

Input.prototype = {
  label: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  type: PropTypes.string,
  error: PropTypes.string,
};

export default Input;
