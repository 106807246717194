import React from "react";
import Styles from "./appLoader.module.scss";
import { Jelly } from "@uiball/loaders";

const TopLoader = ({ children, showLoading = false, className }) => {
  return (
    <div className="relative">
      {showLoading && (
        <div
          className={`${Styles.loaderContainer} ${className} h-full w-full absolute z-10`}
        >
          <Jelly size={30} speed={0.9} color="#17396D" />
          Please wait
        </div>
      )}
      {children}
    </div>
  );
};

export default TopLoader;
