
import React from 'react'
import { Breadcrumb } from '@components'

const Header = ({ title, __hide = false, children, parent, current }) => {
    return (
        <div className='grid grid-cols-12 items-center mt-3 mb-3'>
            <div className='md:col-span-10 col-span-12'>
                <h3>{title}</h3>
                {!__hide && <Breadcrumb parent={parent} current={current} />}
            </div>
            {!__hide && <div className='md:col-span-2 col-span-12 text-right flex justify-end'>
                {children}
            </div>}
        </div>
    )
}

export default Header